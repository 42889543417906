import React, { useEffect, useState, useRef } from 'react';
import {
  Breadcrumbs,
  Grid,
  List,
  makeStyles,
  Paper,
  useMediaQuery
} from '@material-ui/core';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Container from '@material-ui/core/Container';
import { graphql, Link } from 'gatsby';
import Layout from '../../Common/Container';
import clsx from 'clsx';
import HomeIcon from '@material-ui/icons/Home';
import CssBaseline from '@material-ui/core/CssBaseline';
import SEO from '../../Common/SEO/seo';
import Chatbot from '../../Common/Chatbot/Chatbot';

const useStyles = makeStyles(({
  palette,
  ...theme
}) => ({
  card: {
    boxShadow: '12px 0 10px -2px #f7f7f7, -12px 0 10px -2px #f7f7f7',
  },
  selected: {
    color: '#1e6beb !important',
    fontWeight: '500',
    '&:before': {
      content: '\'\'',
      borderLeft: '2px solid',
      height: '20px',
      position: 'absolute',
      marginLeft: '-7px'
    }
  },
  nested: {
    paddingLeft: '20px',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    marginTop: '2px',
    width: 20,
    height: 20,
  },
  link: {
    display: 'flex',
  },
  root: {
    position: 'sticky',
    top: '7rem',
    minWidth: '175px',
    paddingTop: '0px',
    boxShadow: 'none',
    maxHeight: '770px',
    overflowY: 'auto'
  },
}));

const CustomLink = (props) => {
  const href = props.href;
  const isInternalLink = href && (href.startsWith('/') || href.startsWith('#'));
  if (isInternalLink) {
    return <Link to={href} aria-hidden="true" style={{ color: '#1e6beb' }} {...props} />;
  }
  return <a href={href} aria-hidden="true" style={{ color: '#1e6beb' }} {...props} />;
};
const CustomImage = props => {
  return <img {...props} className="max-w-full"/>;
};
const CustomTable = props => {
  return <p>
    <table {...props} style={{ width: '100%' }} className="table"/>
  </p>;
};

const mdxComponents = {
  a: CustomLink,
  p: (props) => <p {...props} />,
  ul: (props) => <ul {...props} />,
  img: CustomImage,
  li: (props) => <p {...props} style={{ lineHeight: '1.6' }}>
    <li {...props} />
  </p>,
  table: CustomTable
};

const ArticleView = ({ data }) => {
  const [selectedIndex, setSelectedIndex] = useState('');
  const classes = useStyles();
  const { edges } = data.allMdx;
  const { frontmatter } = data.mdx;
  const listRef = useRef(null);

  const onClick = (slug) => {
    setSelectedIndex(slug);
  };

  useEffect(() => {
    setSelectedIndex(data.mdx.fields.slug);
  }, [data.mdx.fields.slug]);

  useEffect(() => {
    if (listRef.current && selectedIndex) {
      const selectedElement = listRef.current.querySelector(`.${classes.selected}`);
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
    }
  }, [selectedIndex, classes.selected]);

  const isMobile = useMediaQuery('(max-width: 768px)');
  const { categories } = data;

  return (
    <Layout>
      <Chatbot />
      <CssBaseline />
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        keywords="blogs, multi cloud, DRaaS, disaster recovery, newsfeed, MaaS, cloud migrations, cloud computing, public cloud, multi-cloud migrations, tech news, vpc"
      />
      <section className="section pt-20">
        <Container maxWidth="lg">
          <div className="pt-10">
            <Grid container spacing={0} justify="space-between">
              <Grid item lg={3}>
                <Paper className={classes.root} ref={listRef}>
                  <Breadcrumbs aria-label="breadcrumb" className="mb-1">
                    <Link color="inherit" to="/" className={classes.link}>
                      <HomeIcon className={classes.icon} /> Home
                    </Link>
                    <p style={{ lineHeight: '1', color: '#000' }}>{categories.name}</p>
                  </Breadcrumbs>
                  <List>
                    <ul className="p-0" style={{ listStyle: 'none' }}>
                      {edges.map((item, index) => {
                        return (
                          <div key={index}>
                            {item.node.frontmatter.tab && (
                              <p className="text-15 font-medium mt-4">
                                {item.node.frontmatter.tab}
                              </p>
                            )}
                            <Link to={item.node.fields.slug}>
                              <li className={classes.list}>
                                <p
                                  className={clsx(
                                    selectedIndex === item.node.fields.slug && classes.selected,
                                    'text-13 pl-2'
                                  )}
                                  onClick={() => onClick(item.node.fields.slug)}
                                >
                                  {item.node.frontmatter.title}
                                </p>
                              </li>
                            </Link>
                          </div>
                        );
                      })}
                    </ul>
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12} lg={8}>
                <h1> {data.mdx.frontmatter.title}</h1>
                <MDXProvider components={mdxComponents}>
                  <MDXRenderer>{data.mdx.body}</MDXRenderer>
                </MDXProvider>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default ArticleView;

export const query = graphql`
  query postsBySlugAndPostsBySlug($slug: String, $url: String) {
    categories(url: { eq: $url }) {
      name
    }
    allMdx(
      filter: { frontmatter: { category: { eq: $url } } }
      sort: { fields: frontmatter___index, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            category
            title
            tab
          }
          fields {
            slug
          }
        }
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
      }
      frontmatter {
        title
        description
      }
    }
  }
`;
